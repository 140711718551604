import AuthService from "../services/auth.service";

const checkAuthError = (error) => {
        if (error?.response?.data?.message && 
            (error.response.data.message === 'Unauthorized!' ||
             error.response.data.message === 'No token provided!'
            )){
            AuthService.logout();
        console.log('error', error)
           window.location = '/';
//             window.location.reload();
            return;
        }
     return Promise.reject(error);
}

const chunk_array  = (arr, chunk) => {
    let i,j;
    let to_return = [];
    for (i = 0,j = arr.length; i < j; i += chunk) {    
        to_return.push(arr.slice(i, i + chunk));    
        }

    return to_return;
}

const delay = (time) => {
  return new Promise(resolve => setTimeout(resolve, time));
} 

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Пожалуйста заполните это поле.
            </div>
        );
    }
};

const ChatsList = new Map();
ChatsList.set('common', {title: 'Общий', active: 1})
ChatsList.set('male', {title: 'Мужской клуб', active: 1})
ChatsList.set('female', {title: 'Женский клуб', active: 1})
ChatsList.set('tech', {title: 'Техкомната', active: 1})

export {checkAuthError, chunk_array, ChatsList, delay, required};
