// client/src/pages/chat/send-message.js

import styles from './styles.module.css';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const SendMessage = ({ socket, user, room }) => {
  const [message, setMessage] = useState('');
  
  const handleKeyPress = (event) => {
      if (event.key === 'Enter' && message !== ''){
        const __createdtime__ = Date.now();
      // Send message to server. We can't specify who we send the message to from the frontend. We can only send to server. Server can then send message to rest of users in room
          socket.emit('send_message', {room, message, __createdtime__ });
          setMessage('');
      }
    }

  const sendMessage = () => {
    if (message !== '') {
      const __createdtime__ = Date.now();
      // Send message to server. We can't specify who we send the message to from the frontend. We can only send to server. Server can then send message to rest of users in room
      socket.emit('send_message', {room, message, __createdtime__ });
      setMessage('');
    }
  };

  return (
    <div className={styles.sendMessageContainer}>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Напишите что нибудь"
          aria-label="Напишите что нибудь"
          aria-describedby="sendMessage"
          onChange={(e) => setMessage(e.target.value)}
            value={message}
          onKeyPress={handleKeyPress}
        />
        <Button variant="outline-secondary" id="sendMessage" onClick={sendMessage} >
          Отправить
        </Button>
      </InputGroup>
    </div>
  );
};

export default SendMessage;



/*      
            <input
        className={styles.messageInput}
        placeholder='Message...'
        onChange={(e) => setMessage(e.target.value)}
        value={message}
      />
      <button className='btn btn-primary' onClick={sendMessage}>
        Send Message
      </button>*/
