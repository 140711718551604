import React, { Component } from "react";
import AuthService from "../../services/auth.service";
import { Form as BForm } from 'react-bootstrap';
const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Пожалуйста заполните это поле.
            </div>
        );
    }
};

export default class RequestResetPassword extends Component {
    constructor(props) {
        super(props);
        this.requestReset = this.requestReset.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        
        this.state = {
            email: "",
            loading: false,
            message: "",
            validated: false,
            message_class: "alert-danger",
            isReseted: false
        };
    }
    
    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    
    requestReset(e){
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();

//         this.setState({
//             message: "",
//             loading: true
//         })
        
         if (form.checkValidity() === true) { 
            this.setState({validated: true})
            AuthService.requestResetPassword(this.state.email).then(
                (response) => {
                       this.setState({
                            loading: false,
                            message: response.data.message,
                            validated: false,
                            message_class: "alert-success",
                            isReseted: true
                        });
                    console.log(response)
                },
                error => {
                    const resMessage = 
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message || 
                        error.toString();
                        
                        this.setState({
                            loading: false,
                            message: resMessage,
                            validated: false
                        });
                }
            );
        } else {
            this.setState({
                loading: false,
                validated: false
            });
        }
        
    }

    render(){
        const { validated, message_class, isReseted } = this.state;
        return (
            <div className="col-md-12">
                <div className="card card-container">
                    <img src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card"
                    />
                    
                    <BForm 

                        validated={validated}
                        onSubmit={this.requestReset}
                    >
                    
                    {!isReseted && (
                     <>
                    <div className="form-group">
                        <label htmlFor="email">Почта</label>
                        <BForm.Control
                            type="text"
                            className="form-control"
                            name="email"
                            value={this.state.email}
                            onChange={this.onChangeEmail}
                            validations={[required]}
                        />
                    </div>
                    <br />
                    <div className="d-grid gap-2">
                        <button 
                            className="btn btn-outline-primary"
                            size="lg"
                            disabled={this.state.loading}
                        >
                        
                        {this.state.loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Сбросить</span>
                        </button>
                    </div>
                     </>
                    )}
                    
                    {this.state.message && (
                        <>
                        <br />
                        <div className="form-group">
                            <div className={"alert " + message_class} role="alert">
                                {this.state.message}
                            </div>
                        </div>
                        </>
                    )}
                    </BForm>
                </div>
            </div>
        );
    }
}
