// client/src/pages/home/index.js

import styles from './styles.module.css';
import { Navigate } from "react-router-dom";
import React, { Component } from "react";
import {ChatsList} from '../../helpers/common.js';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
        go_chat: null
    }
  }
  
   joinRoom = () => {

//     const navigate = useNavigate();
    if (this.props.room !== '' && this.props.username !== '') {
      this.props.socket.emit('join_room', { username: this.props.username, room: this.props.room });
      this.setState({go_chat: true});
    }
  };
  
  render() {  
  return (
        <>
      {this.state.go_chat && (
          <Navigate to='/chat'  replace={true} />)}
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <h1>{`<>Комнаты</>`}</h1>
        <input
          className={styles.input}
          placeholder='Ник...'
          onChange={(e) => this.props.setUsername(e.target.value)} 
        />

        <select
          className={styles.input}
          onChange={(e) => this.props.setRoom(e.target.value)} 
        >
        {console.log(ChatsList)}
          { (Object.keys(Object.fromEntries(ChatsList))).map( (key) => <option>-- {ChatsList.get(key).title} --</option> ) }
        </select>

        <button
          className='btn btn-secondary'
          style={{ width: '100%' }}
          onClick={this.joinRoom} // Add this
        >
          Join Room
        </button>
      </div>
    </div>
    </>
  );
}
}

// const Home = ({ username, setUsername, room, setRoom, socket }) => {
//      const navigate = useNavigate();
//     const joinRoom = () => {
//     if (room !== '' && username !== '') {
//       socket.emit('join_room', { username, room });
//     }
//     navigate('/chat', { replace: true });
//   };
//   
//   return (
//     <div className={styles.container}>
//       <div className={styles.formContainer}>
//         <h1>{`<>DevRooms</>`}</h1>
//         <input
//           className={styles.input}
//           placeholder='Username...'
//           onChange={(e) => setUsername(e.target.value)} 
//         />
// 
//         <select
//           className={styles.input}
//           onChange={(e) => setRoom(e.target.value)} 
//         >
//           <option>-- Select Room --</option>
//           <option value='javascript'>JavaScript</option>
//           <option value='node'>Node</option>
//           <option value='express'>Express</option>
//           <option value='react'>React</option>
//         </select>
// 
//         <button
//           className='btn btn-secondary'
//           style={{ width: '100%' }}
//           onClick={joinRoom} // Add this
//         >
//           Join Room
//         </button>
//       </div>
//     </div>
//   );
// };

export default Home;
