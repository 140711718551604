import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import io from 'socket.io-client';
import UserService from "../../services/user.service";

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
} 

class TestListChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: this.props?.messages ?? []
        }
    }
    
         test = async () =>{

        let res = ['Start TestListChat'];
        let chats = await UserService.getChats();
        res.push('End TestListChat');
        
        this.props.setResult(res)
    }
    render(){
        return (
                <>
                    <input type="button" value="TestListChat" onClick={this.test}/>
                </>
        )
    }
}


class TestConnectSocketWithoutAuthTocken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: this.props?.messages ?? []
        }
    }
    
     test = async () =>{
        console.log('set res')
        let res = ['Start TestConnectSocketWithoutAuthTocken'];
        
        const socket = io.connect('https://chat.krskforum.com:4000');
        await delay(1000);
        
        if (socket.connected) {
            res.push('Soket successfull');
        } else 
            res.push('No socket');
        res.push('End TestConnectSocketWithoutAuthTocken');
        
        this.props.setResult(res)
    }
    render(){
        return (
                <>
                    <input type="button" value="TestConnectSocketWithoutAuthTocken" onClick={this.test}/>
                </>
        )
    }
}

class TestConnectSocketWithAuthTocken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: this.props?.messages ?? []
        }
    }
    
     test = async () =>{
        console.log('set res')
        let res = ['Start TestConnectSocketWithAuthTocken'];
        
        const socket = io.connect('https://chat.krskforum.com:4000',{
            auth: { token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY0ODg4MDg0LCJleHAiOjE2NjQ5NzQ0ODR9.mxqnzvLnv-WdxEsCVGGm-4Yuc6TxtMgVKieGkRaJbXY'}
        });
        await delay(1000);
        
        if (socket.connected) {
            res.push('Soket successfull');
        } else 
            res.push('No socket');
        res.push('End TestConnectSocketWithAuthTocken');
        
        this.props.setResult(res)
    }
    render(){
        return (
                <>
                    <input type="button" value="TestConnectSocketWithAuthTocken" onClick={this.test}/>
                </>
        )
    }
}

class TestResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: this.props?.messages ?? []
        }
    }
    
    componentDidUpdate(prevProps){
     if (prevProps.messages !== this.props?.messages)
        this.setState( (prevState) => 
         ( {
            ...prevState,
           messages: this.props?.messages ?? []

            } ) );
    }

    render(){
       const {messages} = this.state;
        return (
            <>{messages.map( (message, index) => <li key={"m_"+ index}>{message} </li>)} </>
        )
    }
}

class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resultTest: []
        }
    }
    setResult = (messages) => {
      this.setState( (prevState) => 
     ( {
        ...prevState,
       resultTest: messages

        } ) );
    }
    render(){
        return (
            <>  <TestResult messages = {this.state.resultTest}/>
                <TestConnectSocketWithoutAuthTocken setResult={this.setResult}/>
                <TestConnectSocketWithAuthTocken setResult={this.setResult}/>
                <TestListChat setResult={this.setResult}/>
            </>
        )
    }
}

export default Test;
