import React, { Component } from "react";
import AuthService from "../../services/auth.service";
import { Navigate } from "react-router-dom";
import { Form as BForm, Alert } from 'react-bootstrap';
import { Link } from "react-router-dom";
import styles from './styles.module.css';
import io from 'socket.io-client';
import {required, delay} from '../../helpers/common.js';

export default class Login extends Component {
    constructor(props) {
        super(props);
//         this.handleLogin = this.handleLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        
        this.state = {
            username: "",
            password: "",
            loading: false,
            message: "",
            validated: false,
            go_chat: false
        };
    }
    
    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }
    
    onChangePassword(e) {
        this.setState({
            password: e.target.value
        })
    }
    
    handleLogin = async (e) => {
        
    const form = e.currentTarget;
    
      e.preventDefault();
      e.stopPropagation();

//         console.log("handle");
//         return;
        this.setState({
            message: "",
            loading: true
        })
        

        
         if (form.checkValidity() === true) { 
            this.setState({validated: true})
            AuthService.login(this.state.username, this.state.password).then(
                () => {
                        if (this.props.socket.connected){
                            this.props.socket.disconnect();
                        }
                        let currentUser = AuthService.getCurrentUser();
//                         const socket = io.connect('https://chat.krskforum.com:4000', {
//                             auth: {
//                                 token: currentUser.accessToken
//                               },
//                               autoConnect: false 
//                         });
                         this.props.socket.auth = {
                            token: currentUser.accessToken
                        }
                        this.props.socket.connect()
                        delay(1000).then(()=>{
                            if (this.props.socket.connected) {
//                              this.props.setSocket();
                               this.props.socket.emit('join_room', {room: 'common'});
                               this.setState({go_chat: true})
                        }
                        });

                   
                },
                error => {
                    const resMessage = 
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message || 
                        error.toString();
                        
                        this.setState({
                            loading: false,
                            message: resMessage,
                            validated: false
                        });
                }
            );
        } else {
            this.setState({
                loading: false,
                validated: false
            });
        }
    }
    
    render(){
        const { validated } = this.state;
        return (
            <>
            {this.state.go_chat && (
                  <Navigate to='/chat'  replace={true} />)}
                  
            <div className="col-md-12">
                <div className="card card-container">
                     <Alert key={"warning"} variant={"warning"}>
                          Внимание, это самостоятельный ресурс, отдельный от форума. <br /> 
                          Пароли от форума не подойдут, необходимо зарегистрироваться.
                     </Alert>
                    
                    <BForm noValidate validated={validated}
                        onSubmit={this.handleLogin}

                    >
                    
                    <div className="form-group">
                        <label htmlFor="username">Ник</label>
                             <BForm.Control 
                                type="text"
                                className="form-control"
                                name="username"
                                validations={[required]}
                                value={this.state.username}
                                onChange={this.onChangeUsername}
                        />
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="password">Пароль</label>
                        <BForm.Control
                            type="password"
                            className="form-control"
                            name="password"
                            value={this.state.password}
                            onChange={this.onChangePassword}
                            validations={[required]}
                        />
                    </div>
                    <hr />
                    <div className="d-grid gap-2">
                        <button 
                            className="btn btn-outline-primary"
                            size="lg"
                            disabled={this.state.loading}
                        >
                        
                        {this.state.loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Войти</span>
                        </button>
                    </div>
                    
                    <div className="form-group" style={{textAlign: 'center'}}>
                         <Link to={"/singup"} className="nav-link">Зарегистрироваться</Link>
                    </div>
                    
                    <div className="form-group" style={{textAlign: 'center'}}>
                         <Link to={"/resetpassword"} className="nav-link">Сбросить пароль</Link>
                    </div>
                    
                    {this.state.message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {this.state.message}
                            </div>
                        </div>
                    )}
                    

                    </BForm>
                </div>
            </div>
            </>
        );
    }
}
