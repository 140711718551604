import axios from "axios";
//require('dotenv').config();

const API_URL = process.env.REACT_APP_API_BASE_URL+process.env.REACT_APP_AUTH_API_PATH;
class AuthService {
    login(username, password){
        return axios
            .post(API_URL+ "signin", {
                username,
                password
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data))
                } else localStorage.removeItem("user");
                return response.data;
            })
    }
    
    logout(){
        localStorage.removeItem("user");
    }
    
    register(username, email, password, sex){
        return axios.post(API_URL + "signup", {
            username,
            email,
            password,
            sex
        });
    }
    
    getCurrentUser(){
        return JSON.parse(localStorage.getItem('user'));
    }
    
    requestResetPassword(email){
        return axios.post(API_URL + "send-reset-password", {
            email
        });
    }
    
    ResetPassword(hash, password = null){
        return axios.post(API_URL + "reset-password", {
            hash, password
        });
        
    }
}


export default new AuthService();
