// src/pages/chat/index.js

import styles from './styles.module.css';
import React, { Component } from "react";
import RoomAndUsers from './room-and-users'; 
import MessagesReceived from './messages';
import SendMessage from './send-message';
import { Navigate } from "react-router-dom";

class Ad extends Component {
    render(){
        return (
            <><div className={styles.ad}> Идет плановое обновление форума, не волнуйтесь.</div></>
        )
    }
}


class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
        go_login: this.props?.socket.connected ? false: true,
        room: 'common',
        user: null
    }
//     if (this.props?.socket && this.props.socket.connected )
//         this.props.socket.emit('join_room', {room: 'common'});
  }
  
  componentDidMount() {
      let user = JSON.parse(localStorage.getItem('user'));
            this.setState( (prevState) => 
     ( {
        ...prevState,
       user

        } ) );
  }
  render() {       
      if (this.state.go_login){
          return (<Navigate to='/'  replace={true} />)
//           return (<>dddd </>)
      } else
    return (
        <>          
        <div className={styles.chatContainer}>
            <RoomAndUsers socket={this.props.socket} user={this.state.user} room={'common'} />
            <div className="messageContainer">
                <MessagesReceived socket={this.props.socket} />
                <SendMessage socket={this.props.socket} user={this.state.user} room={'common'} />
            </div>
            <Ad  />
        </div>
    </>
    )}
}


export default Chat;
