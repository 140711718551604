import axios from 'axios';
import authHeaders from './auth-headers';
import {checkAuthError} from '../helpers/common'

const API_URL = process.env.REACT_APP_API_BASE_URL+'test/';

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    return checkAuthError(error);
  });

class UserService {
    getUsers() {
        let config = {
            headers: authHeaders(),  
        }
        return axios.get(process.env.REACT_APP_API_BASE_URL + 'users', config);
    }
    
    getUser(id) {
        let config = {
            headers: authHeaders(),  
        }
        return axios.get(process.env.REACT_APP_API_BASE_URL + 'users/'+id, config);
    }
    
    saveUser(data) {
        let config = {
            headers: authHeaders(),  
        }
        return axios.post(process.env.REACT_APP_API_BASE_URL + 'users/', data, config);
    }
    

    getRoles() {
        let config = {
            headers: authHeaders(),  
        }
        return axios.get(process.env.REACT_APP_API_BASE_URL + 'roles', config);
    }
    
    getChats() {
        let config = {
            headers: authHeaders(),  
        }
        return axios.get(process.env.REACT_APP_API_BASE_URL + 'chat/list', config);
    }
    
}

export default new UserService();
