// client/src/pages/chat/room-and-users.js

import styles from './styles.module.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserService from "../../services/user.service";




const RoomAndUsers = ({ socket, user, room }) => {
    console.log('RoomAndUsers user', user);
//     let chatRoomUsers = allUsers.filter((user) => user.room === room && !table[user.id] && (table[user.id] =1));
  const [roomUsers, setRoomUsers] = useState([]);
  const [rooms, setRooms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    socket.on('chatroom_users', (data) => {
      console.log('RoomAndUsers chatroom_users', data);
      let table = {};
      let uniqueUsers = data.filter((data) => !table[data.id] && (table[data.id] =1));
//       let chatRoomUsers = allUsers.filter((user) => user.room === room && !table[user.id] && (table[user.id] =1));
      setRoomUsers(uniqueUsers);
    });

    return () => socket.off('chatroom_users');
  }, [socket]);
  
      useEffect(() => {
           async function fetchData() {
                // You can await here
                let rooms = await UserService.getChats();
                if (rooms?.data && rooms.data.length > 0){
//                     console.log('RoomAndUsers rooms0', rooms.data);
                    setRooms(rooms.data);

                }
                // ...
              }
          fetchData();
      }, [socket]);
  
  const leaveRoom = () => {
    const __createdtime__ = Date.now();
    socket.emit('leave_room', { username: user.username, room, __createdtime__ });
    // Redirect to home page
    navigate('/', { replace: true });
  };

  return (
    <div className={styles.roomAndUsersColumn}>
      <h2 className={styles.roomTitle}>{room}</h2>

      <div>
        {roomUsers.length > 0 && <h5 className={styles.usersTitle}>Users:</h5>}
        <ul className={styles.usersList}>
          {roomUsers.map((usr) => (
            <li
              style={{
                fontWeight: `${usr.username === user.username ? 'bold' : 'normal'}`,
              }}
              data_id={usr.id}
              key={usr.socketID}
            >
              {usr.username}
            </li>
          ))}
        </ul>
      </div>

      <button className='btn btn-outline' onClick={leaveRoom}>
        Выйти
      </button>
    </div>
  );
};

export default RoomAndUsers;
