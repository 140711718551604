import React, { Component } from "react";
import { Form as BForm} from 'react-bootstrap';
import { isEmail } from "validator";
import { Link } from "react-router-dom";

import AuthService from "../../services/auth.service";


const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Заполните пожалуйста это поле.
            </div>
        );
    }
};

const email = value => {
    if(!isEmail(value)) {
        return (
          <div className="alert alert-danger" role="alert">
            Почта не похожа на существующую
          </div>
        );
    }
}

const vusername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        Имя должно быть от 3 до 20 символов
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        Пароль должен быть от 6 до 40 символов.
      </div>
    );
  }
};


export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeSex = this.onChangeSex.bind(this);
    
    this.state = {
      username: "",
      email: "",
      password: "",
      successful: false,
      message: "",
      validated: false,
      sex: null
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }
  
  onChangeSex(e) {
    this.setState({
      sex: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
   const form = e.currentTarget;
    
      e.preventDefault();
      e.stopPropagation();

    this.setState({
      message: "",
      successful: false
    });


    if (form.checkValidity() === true) {
      AuthService.register(
        this.state.username,
        this.state.email,
        this.state.password,
        this.state.sex
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true,
            validated: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
      const { validated } = this.state;
      
    return (
      <div className="col-md-12">
        <div className="card card-container">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />

          <BForm noValidate validated={validated}
            onSubmit={this.handleRegister}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="username">Ник</label>
                  <BForm.Control
                    type="text"
                    className="form-control"
                    name="username"
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                    validations={[required, vusername]}
                  />
                </div>
                <div className="form-group">
                    <label htmlFor="sex">Пол</label>
                    <BForm.Select aria-label="Select gender" onChange={this.onChangeSex}>
                        <option>Не определился</option>
                        <option value="M">Мужской</option>
                        <option value="F">Женский</option>
                    </BForm.Select>
                </div>

                <div className="form-group">
                  <label htmlFor="email">Почта</label>
                  <BForm.Control
                    type="text"
                    className="form-control"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    validations={[required, email]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Пароль</label>
                  <BForm.Control
                    type="password"
                    className="form-control"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required, vpassword]}
                  />
                </div>
                <hr />
                <div className="form-group">
                  <button className="btn btn-primary btn-block">Зарегистрироваться</button>
                </div>
              </div>
            )}

            {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
                {this.state.successful && (
                   <span>Вы можете <Link to={"/"} className="nav-link"> войти </Link> в чат.</span>
                )}
              </div>
            )}
          </BForm>
        </div>
      </div>
    );
  }
}
