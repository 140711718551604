import React, { Component } from "react";
import AuthService from "../../services/auth.service";
import { Form as BForm } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    console.log('params', params);
    return (
      <Component
        {...props}
        router={{ location, navigate, params, searchParams,  setSearchParams }}
      />
    );
  }

  return ComponentWithRouterProp;
}

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Заполните пожалуйста это поле.
            </div>
        );
    }
};

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.resetPassword = this.resetPassword.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangePassword2 = this.onChangePassword2.bind(this);
        
        this.state = {
            password: "",
            password2: "",
            loading: false,
            message: "",
            validated: false,
            showForm: false,
            message_class: "alert-danger",
            isChanged: false
        };
    }
    
      componentDidMount(){
        let hash = this?.props?.router?.params?.hash;
        
        AuthService.ResetPassword(hash).then(
                (response) => {
                       this.setState({
                            loading: false,
                            showForm: true,
                        });
                    console.log(response)
                },
                error => {
                    const resMessage = 
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message || 
                        error.toString();
                        
                        this.setState({
                            loading: false,
                            message: resMessage,
                            validated: false
                        });
                }
            );
        
  }
    
    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }
    
    onChangePassword2(e) {
        this.setState({
            password2: e.target.value
        })
    }
    
    resetPassword(e){
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();

        
//         this.setState({
//             message: "",
//             loading: true
//         })
        
         if (form.checkValidity() === true) {
             if (this.state.password !== this.state.password2){
                const resMessage = 'Пароли не совпадают';
                this.setState({
                    loading: false,
                    message: resMessage,
                    validated: false
                });
                return;
            }
            
        AuthService.ResetPassword(this.props?.router?.params?.hash, this.state.password).then(
                (response) => {
                       this.setState({
                            loading: false,
                            showForm: true,
                        });
                    if (undefined !== response.data && undefined !== response.data.success && response.data.success){
                        this.setState({
                            loading: false,
                            showForm: false,
                            isChanged: true,
                            message_class: "alert-success"
                        });
                    } else {
                        
                        this.setState({
                            loading: false,
                            message: "Увы, но что то пошло не так, попробуйте позднее.",
                            validated: false
                        });
                    }
                    console.log(response)
                },
                error => {
                    const resMessage = 
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message || 
                        error.toString();
                        
                        this.setState({
                            loading: false,
                            message: resMessage,
                            validated: false
                        });
                }
            );
            
            
            

        } else {
            console.log("no validate")
            this.setState({
                loading: false,
                validated: false
            });
        }
        
    }

    render(){
        const { validated, showForm, message_class, isChanged } = this.state;
        return (
            <div className="col-md-12">
                <div className="card card-container">
                    <img src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card"
                    />
                    
                    <BForm 

                        validated={validated}
                        onSubmit={this.resetPassword}
                    >
                    
                    {showForm && ( 
                        <>
                            <div className="form-group">
                                <label htmlFor="password">Новый пароль</label>
                                <BForm.Control
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.onChangePassword}
                                    required
                                    validations={[required]}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password2">Повторите пароль</label>
                                <BForm.Control
                                    type="password"
                                    className="form-control"
                                    name="password2"
                                    value={this.state.password2}
                                    onChange={this.onChangePassword2}
                                    required
                                    validations={[required]}
                                />
                            </div>
                            <br />
                            <div className="d-grid gap-2">
                                <button 
                                    className="btn btn-outline-primary"
                                    size="lg"
                                    disabled={this.state.loading}
                                >
                                
                                {this.state.loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Сбросить</span>
                                </button>
                            </div>
                        </>
                    )}
                    
                    {(this.state.message || isChanged) && (
                        <>
                        <br />
                        <div className="form-group">
                            <div className={"alert " + message_class} role="alert">
                            {isChanged && (
                                <span>Пароль изменен. Вы можете <Link to={"/login"} className="nav-link"> войти </Link> используя новый пароль</span>
                            )}
                                {this.state.message}
                            </div>
                        </div>
                        </>
                    )}
                    </BForm>
                </div>
            </div>
        );
    }
}

export default withRouter(ResetPassword);
