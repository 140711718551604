// client/src/App.js

import './App.css';
import React, { Component } from "react";
// import { useState } from 'react';
import Home from './pages/home';
import Chat from './pages/chat';
import  Login  from './pages/login/login.component.js';
import  Register  from './pages/login/register.component.js';
import ResetPassword from "./pages/login/resetpassword.component";
import RequestResetPassword from "./pages/login/requestresetpassword.component";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import io from 'socket.io-client';
import bootstrap from 'bootstrap' 
import 'bootstrap/dist/css/bootstrap.min.css';
import Test from './pages/test/';

const URL = "https://chat.krskforum.com:4000";
const socket = io(URL, { autoConnect: false });

socket.onAny((event, ...args) => {
  console.log('Socket event, args', event, args);
});


class App extends Component {
  constructor(props) {
    super(props);
     this.state = {
      room: null,
    };
  }
    
  setRoom = (room) => {
      this.setState( (prevState) => 
     ( {
        ...prevState,
       room

        } ) );
    }
  render() {
    

    return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path="/"
            element={
              <Login socket={socket} />
            }
          />

          <Route  path="singup"
            element={
              <Register
              />
            }
          />
        <Route  path="reset/:hash" element={<ResetPassword />} />
        <Route path="resetpassword" element={<RequestResetPassword />} />
            
          <Route
            path='/chat/*'
            element={<Chat socket={socket} />}
          />
          
          <Route path='/test' element={<Test />} />
          

        </Routes>
      </div>
    </Router>
  );
  }
}

// function App() {
//   const [username, setUsername] = useState('');
//   const [room, setRoom] = useState('');
// 
//   return (
//     <Router>
//       <div className='App'>
//         <Routes>
//           <Route
//             path='/'
//             element={
//               <Home
//                 username={username}
//                 setUsername={setUsername}
//                 room={room}
//                 setRoom={setRoom}
//                 socket={socket}
//               />
//             }
//           />
// 
//           <Route
//             path='/chat'
//             element={<Chat username={username} room={room} socket={socket} />}
//           />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

export default App;


/*
  // client/src/App.js

import './App.css';
import React, { Component } from "react";
// import { useState } from 'react';
import Home from './pages/home';
import Chat from './pages/chat';
import  Login  from './pages/login/login.component.js';
import  Register  from './pages/login/register.component.js';
import ResetPassword from "./pages/login/resetpassword.component";
import RequestResetPassword from "./pages/login/requestresetpassword.component";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import bootstrap from 'bootstrap' 
import 'bootstrap/dist/css/bootstrap.min.css';
import Test from './pages/test/';

// const socket = io.connect('https://chat.krskforum.com:4000');


class App extends Component {
  constructor(props) {
    super(props);
     this.state = {
      room: null,
      socket: null
    };
  }
    
  setSocket = (socket) => {
          this.setState( (prevState) => ({
              ...prevState,
              socket
        }))
        let user = JSON.parse(localStorage.getItem('user'));
        socket.emit('join_room', {room: 'common'});
  }
  setRoom = (room) => {
      this.setState( (prevState) => 
     ( {
        ...prevState,
       room

        } ) );
    }
  render() {
    

    return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path="/"
            element={
              <Login setSocket={this.setSocket} />
            }
          />

          <Route  path="singup"
            element={
              <Register
              />
            }
          />
        <Route  path="reset/:hash" element={<ResetPassword />} />
        <Route path="resetpassword" element={<RequestResetPassword />} />
            
          <Route
            path='/chat/*'
            element={<Chat socket={this.state.socket} />}
          />
          
          <Route path='/test' element={<Test />} />
          

        </Routes>
      </div>
    </Router>
  );
  }
}

// function App() {
//   const [username, setUsername] = useState('');
//   const [room, setRoom] = useState('');
// 
//   return (
//     <Router>
//       <div className='App'>
//         <Routes>
//           <Route
//             path='/'
//             element={
//               <Home
//                 username={username}
//                 setUsername={setUsername}
//                 room={room}
//                 setRoom={setRoom}
//                 socket={socket}
//               />
//             }
//           />
// 
//           <Route
//             path='/chat'
//             element={<Chat username={username} room={room} socket={socket} />}
//           />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

export default App;
*/
